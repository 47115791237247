
import {defineComponent, onMounted, reactive, toRefs} from "vue";
import ApiLogistics from "@/request/apis/api_logistics";
import router from "@/router";
import {message} from "ant-design-vue/es";
import StorageUtil from "@/utils/storage_util";
import ImageView from '@/components/ImageView.vue'
import LogisticsTypeSet from '@/components/LogisticsTypeSet.vue'
import {ILogisticsTypeItem, ILogisticsTypeUpdateItem} from "@/models/logistics_model";
interface IState {
  list: ILogisticsTypeItem[],
  total: number;
  loading: boolean;
  searches: any
  visible: boolean
  item?: ILogisticsTypeUpdateItem
}

// 表格
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '图标',
    dataIndex: 'icon',
    key: 'icon',
  },
  {
    title: '类型名称',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '权重',
    dataIndex: 'weight',
    key: 'weight',
    align: 'right'
  },
  {
    title: '创建时间',
    dataIndex: 'create_time',
    key: 'create_time',
  },
  {
    title: '状态',
    dataIndex: 'is_enabled',
    key: 'is_enabled',
  },
  {
    title: '操作',
    key: 'action',
  },
];

export default defineComponent({
  name: 'CarType',
  components: { ImageView, LogisticsTypeSet},
  setup() {
    const state: IState = reactive({
      list: [],
      total: 0,
      loading: false,
      visible: false,
      item: undefined,
      searches: {},
    })

    //获取列表
    const getList = async () => {
      let params = {...state.searches}
      if (params.status === '') {
        delete params.status
      }
      if (params.name === '') {
        delete params.name
      }
      StorageUtil.setParams(params)
      state.loading = true
      const res = await ApiLogistics.getLogisticsCategoryList(params)
      state.loading = false
      if (res && res.error_code === 0) {
        state.total = res.data?.count || 0
        state.list = res.data?.list || []
      }
    }

    // 启用禁用
    const setStatus = async (item: ILogisticsTypeItem) => {
      state.loading = true
      const res = await ApiLogistics.setLogisticsCategoryStatus(item.id,  !item.is_enabled)
      message.destroy()
      state.loading = false
      if (res && res.error_code === 0) {
        message.success('设置成功')
        await getList()
      } else {
        message.error(res?.msg || '设置失败');
      }
    }

    const setVisible = (visible: boolean, item: ILogisticsTypeItem | undefined = undefined) => {
      state.visible = visible
      if(visible){
        state.item = {
          id: item ? item.id : '',
          name: item ? item.name : '',
          is_enabled: item ? Boolean(item.is_enabled) : true,
          weight: item ? item.weight : '',
          icon: item?.icon || ''
        }
      }else{
        setTimeout(() => state.item = undefined, 300)
      }
    }

    const onClose = () => {
      setVisible(false)
      getList()
    }

    // 去详情
    const goDetail = (item: ILogisticsTypeItem, type: 'comment' | 'order' = 'comment') => {
      router.push({path: '/comment/detail', query: {id: item.id}}).then()
    }

    // 搜索
    const onSearch = (key: string, value: any, e: any) => {
      state.searches[key] = value
      getList()
    }

    // 重置search参数
    const resetSearch = () => {
      for (let key in state.searches) {
        state.searches[key] = ''
      }
      getList()
    }

    // 初始化search参数
    const initSearch = () => {
      const res = StorageUtil.getParams()
      if (res) {
        state.searches = {...state.searches, ...res}
      }
    }

    onMounted(() => {
      initSearch()
      getList()
    })

    return {
      ...toRefs(state),
      columns,
      onSearch,
      resetSearch,
      goDetail,
      setStatus,
      setVisible,
      onClose
    }
  }
})
